import './App.css';
import {useEffect, useState} from 'react';
import Results from './Results';
import prepareRates from './prepareRates';

function App() {
  const [rates, setRates] = useState(null)
  const [money, setMoney] = useState(0)
  useEffect(() => {
    fetch('/rates')
      .then(res => res.json())
      .then(prepareRates)
      .then(setRates)
  }, [])

  return (
    <div className="App">
      <div className="flex flex-col items-center justify-center h-[60vh] bg-gray-100">
      <h1 className="text-5xl md:text-6xl font-bold text-gray-800 mb-4 text-center">
        Где я миллионер?
      </h1>
      <h5 className="text-lg md:text-xl text-gray-600 mb-2 text-center">
        Впиши, сколько у тебя денег всего, а я расскажу
      </h5>
      <p className="text-sm md:text-base text-gray-500 mb-6">
        Курс обновляется раз в день
      </p>
      <input
        placeholder="Сколько у тебя рублей?"
        value={money}
        onChange={(e) => {
          const value = e.target.value;

          if (/^\d*$/.test(value)) {
            setMoney(value);
          }
        }}
        className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 appearance-none"
      />
    </div>


      {(rates && money) ? <Results money={money} rates={rates}/> : ''}
    </div>
  );
}

export default App;

import {getPluralCurrencyName} from "./currencyNames";



function Results({rates, money}) {
    const millionaireCount = rates.filter(rate => money * rate.rate > 1_000_000).length;

    return (
    <div>
        <div className="text-center text-lg font-semibold my-4">
      {millionaireCount > 0
        ? `Ого! У тебя миллион в ${millionaireCount} валют${millionaireCount === 1 ? 'е' : millionaireCount < 5 ? 'ах' : 'ах'}!`
        : 'Нищук реально 😔'}
    </div>
    <ul className="w-full max-w-4xl mx-auto space-y-4 mt-8 pb-8">
        {rates.map((rate) => {
          const amount = money * rate.rate;
          const isMillionaire = amount >= 1_000_000;
          const formattedAmount = new Intl.NumberFormat('ru-RU', {
            style: 'decimal',
            maximumFractionDigits: 2,
          }).format(amount);

          return (
            <li
              key={rate.name}
              className={`flex items-center justify-between p-4 border rounded-lg shadow-md ${
                isMillionaire ? "bg-green-100 border-green-300" : "bg-red-100 border-red-300"
              }`}
            >
              <span className="text-lg font-semibold">
                {formattedAmount} {getPluralCurrencyName(amount, rate.code)}
              </span>
              <span className="text-2xl">{rate.emoji}</span>
            </li>
          );
        })}
      </ul>
      </div>)
}

export default Results;
const currencyNames = {
    RUB: ['рубль', 'рубля', 'рублей'],
    AED: ['дирхам', 'дирхама', 'дирхамов'],
    AFN: ['афгани', 'афгани', 'афгани'],
    ALL: ['лек', 'лека', 'леков'],
    AMD: ['драм', 'драма', 'драмов'],
    ANG: ['гульден', 'гульдена', 'гульденов'],
    AOA: ['кванза', 'кванзы', 'кванз'],
    ARS: ['песо', 'песо', 'песо'],
    AUD: ['австралийский доллар', 'австралийских доллара', 'австралийских долларов'],
    AWG: ['флорин', 'флорина', 'флоринов'],
    AZN: ['манат', 'маната', 'манатов'],
    BAM: ['конвертируемая марка', 'конвертируемые марки', 'конвертируемых марок'],
    BBD: ['барбадосский доллар', 'барбадосских доллара', 'барбадосских долларов'],
    BDT: ['така', 'таки', 'так'],
    BGN: ['лев', 'лева', 'левов'],
    BHD: ['динар', 'динара', 'динаров'],
    BIF: ['бурундийский франк', 'бурундийских франка', 'бурундийских франков'],
    BMD: ['бермудский доллар', 'бермудских доллара', 'бермудских долларов'],
    BND: ['брунейский доллар', 'брунейских доллара', 'брунейских долларов'],
    BRL: ['реал', 'реала', 'реалов'],
    BSD: ['багамский доллар', 'багамских доллара', 'багамских долларов'],
    BTN: ['нгултрум', 'нгултрума', 'нгултрумов'],
    BWP: ['пула', 'пулы', 'пул'],
    BYN: ['белорусский рубль', 'белорусских рубля', 'белорусских рублей'],
    CAD: ['канадский доллар', 'канадских доллара', 'канадских долларов'],
    CDF: ['конголезский франк', 'конголезских франка', 'конголезских франков'],
    CHF: ['швейцарский франк', 'швейцарских франка', 'швейцарских франков'],
    CNY: ['юань', 'юаня', 'юаней'],
    COP: ['песо', 'песо', 'песо'],
    CRC: ['колон', 'колона', 'колонов'],
    CUP: ['кубинский песо', 'кубинских песо', 'кубинских песо'],
    CZK: ['чешская крона', 'чешские кроны', 'чешских крон'],
    DKK: ['датская крона', 'датские кроны', 'датских крон'],
    DJF: ['франк Джибути', 'франка Джибути', 'франков Джибути'],
    DOP: ['доминиканский песо', 'доминиканских песо', 'доминиканских песо'],
    EGP: ['египетский фунт', 'египетских фунта', 'египетских фунтов'],
    EUR: ['евро', 'евро', 'евро'],
    GBP: ['фунт стерлингов', 'фунта стерлингов', 'фунтов стерлингов'],
    GEL: ['лари', 'лари', 'лари'],
    HKD: ['гонконгский доллар', 'гонконгских доллара', 'гонконгских долларов'],
    IDR: ['рупия', 'рупии', 'рупий'],
    ILS: ['новый шекель', 'новых шекеля', 'новых шекелей'],
    INR: ['индийская рупия', 'индийские рупии', 'индийских рупий'],
    ISK: ['исландская крона', 'исландские кроны', 'исландских крон'],
    JPY: ['иена', 'иены', 'иен'],
    KRW: ['вона', 'воны', 'вон'],
    KZT: ['тенге', 'тенге', 'тенге'],
    MNT: ['тугрик', 'тугрика', 'тугриков'],
    NOK: ['норвежская крона', 'норвежские кроны', 'норвежских крон'],
    PHP: ['филиппинское песо', 'филиппинских песо', 'филиппинских песо'],
    PLN: ['злотый', 'злотых', 'злотых'],
    RUB: ['рубль', 'рубля', 'рублей'],
    SEK: ['шведская крона', 'шведские кроны', 'шведских крон'],
    SGD: ['сингапурский доллар', 'сингапурских доллара', 'сингапурских долларов'],
    THB: ['бат', 'бата', 'батов'],
    TRY: ['лира', 'лиры', 'лир'],
    USD: ['доллар', 'доллара', 'долларов'],
    VND: ['донг', 'донга', 'донгов'],
    ZAR: ['ранд', 'ранда', 'рандов'],
    BOB: ['боливиано', 'боливиано', 'боливиано'],
  BZD: ['белизский доллар', 'белизских доллара', 'белизских долларов'],
  CLP: ['чилийское песо', 'чилийских песо', 'чилийских песо'],
  CVE: ['эскудо', 'эскудо', 'эскудо'],
  DZD: ['алжирский динар', 'алжирских динара', 'алжирских динаров'],
  ERN: ['накфа', 'накфы', 'накф'],
  ETB: ['эфиопский быр', 'эфиопских быра', 'эфиопских быров'],
  FJD: ['фиджийский доллар', 'фиджийских доллара', 'фиджийских долларов'],
  FKP: ['фунт Фолклендских островов', 'фунта Фолклендских островов', 'фунтов Фолклендских островов'],
  FOK: ['фарерская крона', 'фарерские кроны', 'фарерских крон'],
  GEL: ['лари', 'лари', 'лари'],
  GGP: ['фунт Гернси', 'фунта Гернси', 'фунтов Гернси'],
  GHS: ['седи', 'седи', 'седи'],
  GIP: ['гибралтарский фунт', 'гибралтарских фунта', 'гибралтарских фунтов'],
  GMD: ['даласи', 'даласи', 'даласи'],
  GNF: ['гвинейский франк', 'гвинейских франка', 'гвинейских франков'],
  GTQ: ['кетсаль', 'кетсаля', 'кетсалей'],
  GYD: ['гайанский доллар', 'гайанских доллара', 'гайанских долларов'],
  HNL: ['лемпира', 'лемпиры', 'лемпир'],
  HRK: ['куна', 'куны', 'кун'],
  HTG: ['гурд', 'гурда', 'гурдов'],
  HUF: ['форинт', 'форинта', 'форинтов'],
  IMP: ['фунт острова Мэн', 'фунта острова Мэн', 'фунтов острова Мэн'],
  IQD: ['иракский динар', 'иракских динара', 'иракских динаров'],
  IRR: ['риал', 'риала', 'риалов'],
  JEP: ['фунт Джерси', 'фунта Джерси', 'фунтов Джерси'],
  JMD: ['ямайский доллар', 'ямайских доллара', 'ямайских долларов'],
  JOD: ['иорданский динар', 'иорданских динара', 'иорданских динаров'],
  KES: ['кенийский шиллинг', 'кенийских шиллинга', 'кенийских шиллингов'],
  KGS: ['сом', 'сома', 'сомов'],
  KHR: ['риель', 'риеля', 'риелей'],
  KID: ['доллар Кирибати', 'доллара Кирибати', 'долларов Кирибати'],
  KMF: ['коморский франк', 'коморских франка', 'коморских франков'],
  KWD: ['кувейтский динар', 'кувейтских динара', 'кувейтских динаров'],
  KYD: ['доллар Каймановых островов', 'доллара Каймановых островов', 'долларов Каймановых островов'],
  LAK: ['кип', 'кипа', 'кипов'],
  LBP: ['ливанский фунт', 'ливанских фунта', 'ливанских фунтов'],
  LKR: ['шри-ланкийская рупия', 'шри-ланкийские рупии', 'шри-ланкийских рупий'],
  LRD: ['либерийский доллар', 'либерийских доллара', 'либерийских долларов'],
  LSL: ['лоти', 'лоти', 'лоти'],
  LYD: ['ливийский динар', 'ливийских динара', 'ливийских динаров'],
  MAD: ['марокканский дирхам', 'марокканских дирхама', 'марокканских дирхамов'],
  MDL: ['молдавский лей', 'молдавских лея', 'молдавских леев'],
  MGA: ['ариари', 'ариари', 'ариари'],
  MKD: ['македонский денар', 'македонских динара', 'македонских динаров'],
  MMK: ['кьят', 'кьята', 'кьятов'],
  MOP: ['патака', 'патаки', 'патак'],
  MRU: ['угия', 'угии', 'угий'],
  MUR: ['маврикийская рупия', 'маврикийские рупии', 'маврикийских рупий'],
  MVR: ['руфия', 'руфии', 'руфий'],
  MWK: ['квача', 'квачи', 'квач'],
  MXN: ['мексиканское песо', 'мексиканских песо', 'мексиканских песо'],
  MYR: ['малайзийский ринггит', 'малайзийских ринггита', 'малайзийских ринггитов'],
  MZN: ['метикал', 'метикала', 'метикалов'],
  NAD: ['доллар Намибии', 'доллара Намибии', 'долларов Намибии'],
  NGN: ['найра', 'найры', 'найр'],
  NIO: ['кордоба', 'кордобы', 'кордоб'],
  NPR: ['непальская рупия', 'непальские рупии', 'непальских рупий'],
  NZD: ['новозеландский доллар', 'новозеландских доллара', 'новозеландских долларов'],
  OMR: ['оманский риал', 'оманских риала', 'оманских риалов'],
  PAB: ['бальбоа', 'бальбоа', 'бальбоа'],
  PEN: ['соль', 'соля', 'солей'],
  PGK: ['кина', 'кины', 'кин'],
  PKR: ['пакистанская рупия', 'пакистанские рупии', 'пакистанских рупий'],
  PYG: ['гуарани', 'гуарани', 'гуарани'],
  QAR: ['риал Катара', 'риала Катара', 'риалов Катара'],
  RON: ['румынский лей', 'румынских лея', 'румынских леев'],
  RSD: ['сербский динар', 'сербских динара', 'сербских динаров'],
  RWF: ['франк Руанды', 'франка Руанды', 'франков Руанды'],
  SAR: ['риал Саудовской Аравии', 'риала Саудовской Аравии', 'риалов Саудовской Аравии'],
  SBD: ['доллар Соломоновых островов', 'доллара Соломоновых островов', 'долларов Соломоновых островов'],
  SCR: ['сейшельская рупия', 'сейшельские рупии', 'сейшельских рупий'],
  SDG: ['суданский фунт', 'суданских фунта', 'суданских фунтов'],
  SHP: ['фунт Святой Елены', 'фунта Святой Елены', 'фунтов Святой Елены'],
  SLE: ['леоне', 'леоне', 'леоне'],
  SLL: ['леоне', 'леоне', 'леоне'],
  SOS: ['сомалийский шиллинг', 'сомалийских шиллинга', 'сомалийских шиллингов'],
  SRD: ['суринамский доллар', 'суринамских доллара', 'суринамских долларов'],
  SSP: ['южносуданский фунт', 'южносуданских фунта', 'южносуданских фунтов'],
  STN: ['добра', 'добры', 'добр'],
  SYP: ['сирийский фунт', 'сирийских фунта', 'сирийских фунтов'],
  SZL: ['лилангени', 'лилангени', 'лилангени'],
  TJS: ['сомони', 'сомони', 'сомони'],
  TMT: ['манат', 'маната', 'манатов'],
  TND: ['тунисский динар', 'тунисских динара', 'тунисских динаров'],
  TOP: ['паанга', 'паанги', 'паанг'],
  TTD: ['доллар Тринидада и Тобаго', 'доллара Тринидада и Тобаго', 'долларов Тринидада и Тобаго'],
  TVD: ['доллар Тувалу', 'доллара Тувалу', 'долларов Тувалу'],
  TWD: ['новый тайваньский доллар', 'новых тайваньских доллара', 'новых тайваньских долларов'],
  TZS: ['шиллинг Танзании', 'шиллинга Танзании', 'шиллингов Танзании'],
  UAH: ['гривна', 'гривны', 'гривен'],
  UGX: ['угандийский шиллинг', 'угандийских шиллинга', 'угандийских шиллингов'],
  UYU: ['уругвайское песо', 'уругвайских песо', 'уругвайских песо'],
  UZS: ['сум', 'сума', 'сумов'],
  VES: ['боливар', 'боливара', 'боливаров'],
  VUV: ['вату', 'вату', 'вату'],
  WST: ['тала', 'талы', 'тал'],
  XAF: ['франк КФА', 'франка КФА', 'франков КФА'],
  XCD: ['восточно-карибский доллар', 'восточно-карибских доллара', 'восточно-карибских долларов'],
  XDR: ['СДР', 'СДР', 'СДР'],
  XOF: ['франк КФА', 'франка КФА', 'франков КФА'],
  XPF: ['франк КФП', 'франка КФП', 'франков КФП'],
  YER: ['йеменский риал', 'йеменских риала', 'йеменских риалов'],
  ZMW: ['квача Замбии', 'квачи Замбии', 'квач Замбии'],
  ZWL: ['зимбабвийский доллар', 'зимбабвийских доллара', 'зимбабвийских долларов']
  };

export const getPluralCurrencyName = (amount, currencyCode) => {
    const forms = currencyNames[currencyCode] || [currencyCode, currencyCode, currencyCode];
    const pluralRules = new Intl.PluralRules('ru-RU');
    const pluralCategory = pluralRules.select(amount);

    switch (pluralCategory) {
      case 'one':
        return forms[0]; // Singular
      case 'few':
        return forms[1]; // Few
      default:
        return forms[2]; // Many
    }
  };
import {Currencies} from 'currencies-map';
import {getEmojiByCurrencyCode} from "country-currency-emoji-flags";



export default function prepareRates(rates) {
    const ratesArray = Object.entries(rates.conversion_rates)
        .map(([code, value]) => ({
            code,
            rate: value,
            name: Currencies.names.get(code),
            emoji: getEmojiByCurrencyCode(code)
        }))
        .filter(({name}) => !!name)

    ratesArray.sort((a, b) => b.rate - a.rate)

    return ratesArray;
}